import cssVars from 'css-vars-ponyfill';

cssVars({
	variables: {
		'primary': '17, 23, 163',
		'primary-dark': '0, 0, 81',
		'accent': '245, 0, 87',
		'accent-plus': '255, 255, 255',
	},
});
