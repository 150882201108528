import reducer from './js/app/reducer';
import './SchemeColor';

window.kinosite = {
	reducer,
	additionalModules: {
		gosuslugiPage: {
			getData: () => import('./js/templates/gosuslugi.html')
		}
	}
};
